const projects = [
    {
        title: "Snow.place",
        description: "I created Snow.place to help compare Discord timestamps from IDs. @Carcraftz and I worked on the website. I created the Discord bot for more convenient comparing.",
        url: "https://snow.place",
        tags: ["Development", "UI Design", "Branding"],
        img: "snowplace.svg",
        color: "#121518"
    },
    {
        title: "Jigged",
        description: "I have been working with Jigged since May 2020.",
        url: "https://jigged.io",
        tags: ["Development", "Design", "Motion", "Editing"],
        img: "jigged.png",
        color: "#151521"
    },
    {
        title: "Elva",
        description: "I worked on the branding, UI, and motion design for Elva.",
        url: "https://elva.gg",
        tags: ["UI Design", "Branding", "Motion"],
        img: "elva.png",
        color: "#1E1E1E"
    },
    {
        title: "Reaction Picker",
        description: "Reaction Picker is a Discord bot I created for making giveaways on any message.",
        url: "https://github.com/smuke/reaction-picker",
        tags: ["Development"],
        img: "dead.png",
        color: "#23272a"
    },
    {
        title: "Alpha Essentials",
        description: "I created the 'A' letter mark logo for Alpha Essentials.",
        url: "https://twitter.com/AlphaEssentiaIs",
        tags: ["Branding"],
        img: "alpha.svg",
        color: "#0f1015"
    }
]

export default projects;