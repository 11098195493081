import React from "react";

import { Box, Flex, Heading, Text, Link } from "@chakra-ui/react";
import ProjectIcon from "./ProjectIcon";
import Tag from "./Tag";
import { ExternalLinkIcon } from "@chakra-ui/icons";

export default function Project(props) {
    return (
        <Link href={props.url} isExternal w="100%" mb={5}>
            <Box border="1px" borderColor="#1C1C1C" borderRadius="lg" w="100%" h={{base: "auto", lg: "250px"}} p={10} pos="relative">
                <Flex flexDirection={{ base:"column", lg: "row" }}>
                    <ProjectIcon background={props.color} img={props.img} alt={props.title} />
                    <Flex flexDirection="column" justifyContent="space-between" p={2}>
                        <div>
                            <Heading as="h2" size="md" mb={2}>{props.title}</Heading>
                            <Text fontSize="md" pr={10} color="gray 800">{props.description}</Text>
                        </div>
                        <Flex flexWrap="wrap" mt={{ base: 10, lg: 0 }}>
                            {props.tags.map((name, index) => <Tag key={index} name={name} />)}
                        </Flex>
                    </Flex>
                    <ExternalLinkIcon pos="absolute" right={10} />
                </Flex>
            </Box>
        </Link>
    );
}