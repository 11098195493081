import { extendTheme } from "@chakra-ui/react";

const config = {
    initialColorMode: "dark",
    useSystemColorMode: false,
}

const fonts = {
    heading: "Nunito",
    body: "Nunito"
}

const styles = {
    global: {
        body: {
            bg: "black",
            color: "white"
        }
    }
}

const components = {
    Heading: {
        baseStyle: {
            fontWeight: "normal"
        }
    }
}


const theme = extendTheme({ config, fonts, styles, components });

export default theme;