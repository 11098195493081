import React from "react";

import { Box, Image, Flex } from "@chakra-ui/react";

export default function ProjectIcon(props) {

    return (
        <Box bg={props.background} borderRadius="lg" h={40} w={40} minW={40} mr={10} mb={{ base: 5, lg: 0 }}>
            <Flex justifyContent="center" alignItems="center" height="100%">
                <Image src={`../.././images/projects/${props.img}`} alt={props.alt} maxH="50px" maxW="75px" />
            </Flex>
        </Box>
    )
}