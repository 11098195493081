import React from "react";

import "@fontsource/nunito/700.css";
import { Flex, Heading } from "@chakra-ui/react";
import NowPlaying from "./NowPlaying";
import Logo from "./Logo";

export default function Header() {
    return (
        <Flex alignItems={{ base: "center", md: "flex-start" }} flexDirection="column" mb="48">
            <Logo />
            <Heading as="h1" size="lg">Design &amp; development</Heading>
            <NowPlaying />
        </Flex>
    );
}