import React from "react";

import "@fontsource/nunito";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import { Flex } from "@chakra-ui/react";

import Header from "./components/Header";
import Main from "./components/Main";

export default function App() {
  return (
    <ChakraProvider theme={theme}>
      <Flex
        direction="column"
        align={{ base: "center", md: "flex-start" }}
        margin="0 auto"
        maxW={{ xl: "1200px", lg: "900px", md: "700px", sm: "400px", base: "300px"}}
      >
        <Header />
        <Main />
      </Flex>
    </ChakraProvider>
  );
}
