import axios from "axios";
import React, { useState, useEffect } from "react";
import dotenv from "dotenv";
dotenv.config();

export default function NowPlaying() {
    const token = process.env.REACT_APP_SPOTIFY_TOKEN;
    const [nowPlaying, setNowPlaying] = useState("");

    useEffect(() => {
        axios.get("https://api.spotify.com/v1/me/player/currently-playing", {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(res => {
            if (res.data.item) {
                const artists = res.data.item.artists.map(artist => artist.name).join(", ");
                const song = res.data.item.name;
                const album = res.data.item.album.name;

                const full = `${artists} - ${song} on ${album}`;
                const short = full.slice(0, 70).trim();
                if (full.length > 70) {
                    setNowPlaying(short + "...");
                } else {
                    setNowPlaying(short);
                }
            } else {
                setNowPlaying("");
            }
        });
    });

    return <p style={{ marginTop: "20px", color: "#4fc865" }}>
        {nowPlaying && <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ display: "inline", marginRight: "5px" }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 8.95439L17.6352 7.82736C19.0102 7.55235 20 6.34501 20 4.94274C20 3.08637 18.3017 1.69406 16.4813 2.05813L12.4117 2.87206C11.0094 3.15252 10 4.38376 10 5.8138V13.8895C9.41165 13.5492 8.72857 13.3544 8 13.3544C5.79086 13.3544 4 15.1453 4 17.3544C4 19.5635 5.79086 21.3544 8 21.3544C10.2091 21.3544 12 19.5635 12 17.3544V8.95439ZM16.8736 4.01929L12.8039 4.83322C12.3365 4.92671 12 5.33712 12 5.8138V6.91479L17.2429 5.8662C17.6831 5.77816 18 5.39165 18 4.94274C18 4.34846 17.4563 3.90274 16.8736 4.01929ZM10 17.3544C10 16.2498 9.10457 15.3544 8 15.3544C6.89543 15.3544 6 16.2498 6 17.3544C6 18.459 6.89543 19.3544 8 19.3544C9.10457 19.3544 10 18.459 10 17.3544Z"
                fill="#4fc865"
            />
            <animateTransform 
                attributeName="transform"
                type="scale"
                from="1.1"
                to=".9"
                dur="2s"
                keySplines="0.5 0 0.5 1; 0.5 0 0.5 1; 0.5 0 0.5 1; 0.5 0 0.5 1"
                repeatCount="indefinite"
            />
            <animate 
                attributeName="opacity"
                type="opacity"
                from=".75"
                to="1"
                dur="2s"
                keySplines="0.5 0 0.5 1; 0.5 0 0.5 1; 0.5 0 0.5 1; 0.5 0 0.5 1"
                repeatCount="indefinite"
            />
        </svg>}
        {nowPlaying}</p>
}