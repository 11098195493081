import React from "react";

import { Flex } from "@chakra-ui/react";

import Project from "./Project";
import projects from "../projects";

export default function Main() {
    return (
        <Flex flexDirection="column" w="100%" mb={52}>
            {projects.map((item, index) => {
                return <Project 
                    key={index}
                    id={index}
                    title={item.title}
                    description={item.description}
                    url={item.url}
                    img={item.img}
                    tags={item.tags}
                    color={item.color}
                />
            })}
        </Flex>
    )
}